<template>
  <div class="bgg">
    <div class="container-fluid">
      <Header />
      <router-view />
    </div>
  </div>
</template>

// <script>
import Header from "./components/Header.vue";
import axios from "axios";

export default {
  components: {
    Header,
  },
  mounted() {
    if (localStorage.getItem("accessToken") == null) return;
    this.$store.commit("setAccessToken", localStorage.getItem("accessToken"));
    axios
      .get(`${this.$url}/api/user/own`, {
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((response) => {
        this.userdata = response.data;
        this.$store.commit("addUserData", response.data);
      })
      .catch(() => {});
  },
};
</script>


<style>
@import "./assets/style.css";
html {
  height: 100%;
}
body,
.bgg {
  bottom: 0;
  margin: 0;
  height: 100%;
  /* background-color: #354f52ff; */
  background: rgb(31, 30, 36);
  background: linear-gradient(
    120deg,
    rgba(31, 30, 36, 1) 0%,
    rgba(43, 72, 93, 1) 21%,
    rgba(56, 81, 101, 1) 50%,
    rgba(64, 77, 89, 1) 67%,
    rgba(50, 56, 66, 1) 87%,
    rgba(48, 47, 54, 1) 100%
  );
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

button {
  cursor: pointer;
  color: #fff;
  background-color: #6c757d;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  margin: 5px;
  margin-top: 20px;
  color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button.accept {
  background-color: #28a745;
}

button.cancel {
  background-color: #6c757d;
}
button.warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
button.danger {
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>
