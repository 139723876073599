import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import './styles/style.scss'

import store from './store';

var env = process.env.NODE_ENV || 'development';
const app = createApp(App);
app.config.globalProperties.$url = env == 'development' ? "http://localhost:3000" : "https://beniox.lerchen.net";
app.use(store);
app.use(router);
app.mount('#app');
