<template>
  <form @submit.prevent="handleSubmit">
    <h1>Register</h1>
    <label>Username:</label>
    <input type="text" required v-model="username" v-on:blur="testUsername" />
    <div
      id="nameStatusDiv"
      v-if="usernameStatus"
      v-bind:class="usernameStatus == 'Available' ? 'success' : 'error'"
    >
      {{ usernameStatus }}
    </div>
    <label>Password:</label>
    <input type="password" required v-model="password" @blur="testPassword" />
    <div v-if="passwordError" class="error">{{ passwordError }}</div>

    <!-- <label>Role:</label>
    <select v-model="role">
      <option value="developer">Web Developer</option>
      <option value="designer">Web Designer</option>
    </select> -->

    <div class="terms">
      <input type="checkbox" required v-model="terms" />
      <label>Accept terms and conditions</label>
    </div>

    <div class="submit">
      <button
        type="button"
        class="btn btn-secondary"
        @click="this.$emit('changeForm', 'login')"
      >
        Login instead
      </button>
      <button type="submit" class="btn btn-success">Create Account</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      role: "designer",
      terms: false,
      passwordError: "",
      usernameStatus: "",
    };
  },
  methods: {
    handleSubmit: async function () {
      if (this.usernameStatus == "Unavailable" || this.passwordError != "")
        return;
      try {
        await axios.post(`${this.$url}/api/user/register`, {
          username: this.username,
          password: this.password,
        });
        console.log("registerd");

        const response = await axios.post(`${this.$url}/api/user/login`, {
          username: this.username,
          password: this.password,
        });

        if (response.status == 200) {
          localStorage.setItem("accessToken", response.data.accessToken);
          this.$store.commit(
            "setAccessToken",
            localStorage.getItem("accessToken")
          );
          this.$emit("status", "registered");
          this.loginError = false;
        } else {
          console.log("Failed to login");
          this.loginError = true;
        }
      } catch (err) {
        console.log("EError");
      }

      // //login directly
      // axios
      //   .post(`${this.$url}/user/login`, {
      //     username: this.username,
      //     password: this.password,
      //   })
      //   .then((response) => {
      //     if (response.status == 200) {
      //       // console.log("login successfull");
      //       localStorage.setItem("accessToken", response.data.accessToken);
      //       this.$store.commit(
      //         "setAccessToken",
      //         localStorage.getItem("accessToken")
      //       );
      //       this.loginError = false;
      //     } else {
      //       console.log("Failed to login");
      //       this.loginError = true;
      //     }
      //   })
      //   .catch(() => {
      //     this.loginError = true;
      //   });
    },
    testPassword() {
      //validate password
      this.passwordError =
        this.password.length > 5
          ? ""
          : "Password must be at least 6 chars long";
    },
    testUsername: function () {
      if (this.username.length == 0) return;
      axios
        .post(`${this.$url}/api/user/registername`, {
          username: this.username,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "unavailable") {
            this.usernameStatus = "Unavailable";
          } else {
            this.usernameStatus = "Available";
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
label {
  color: hsl(0, 0%, 50%);
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}
.submit {
  text-align: center;
}
.error {
  color: red;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}

.success {
  color: rgb(1, 133, 45);
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}
h1 {
  text-align: center;
}
</style>