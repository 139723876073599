<template class="cointainer">
  <section class="container bg">
    <article v-if="survey != null && results != null" class="container">
      <h1>{{ survey.name }}</h1>
      <h2>Results: {{ results.length }}</h2>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th
              v-for="question in this.survey.questions"
              :key="question"
              scope="col"
            >
              {{ question.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in this.results" :key="result">
            <th scope="row">{{ index + 1 }}</th>
            <td v-for="answer in results[index].answers" :key="answer">
              {{ answer.answer }}
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </section>
</template>

<script>
const axios = require("axios");
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      results: null,
      survey: null,
    };
  },
  methods: {
    getSurvey: async function () {
      try {
        const survey = await axios.get(`${this.$url}/api/survey/${this.id}`, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        });
        this.survey = survey.data;
        console.log("got survey");
      } catch (error) {
        console.log(error);
      }
    },

    getResults: async function () {
      try {
        const results = await axios.get(
          `${this.$url}/api/survey/${this.id}/result`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          }
        );
        this.results = results.data;
        console.log(results.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSurvey();
    this.getResults();
  },
};
</script>

<style>
.bg {
  background-color: white;
  border-radius: 10px;
  border: 1px solid black;
}
</style>