import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import AdminView from '../views/AdminView.vue';
import AccountView from '../views/AccountView.vue';
import ContactView from '../views/ContactView';
import SettingsView from '../views/SettingsView';
import ToDoview from '../views/ToDoView';
import SurveyView from '../views/SurveyView';
import SurveyEditView from '../views/SurveyEditView';
import SurveyResultView from '../views/SurveyResultView';

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
},
{
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ "../views/About.vue"),
},
{
    path: "/account",
    name: "Account",
    component: AccountView,
},
{
    path: "/admin",
    name: "Admin",
    component: AdminView,
    meta: {
        auth: true
    }
},
{
    path: "/contact",
    name: "Contact",
    component: ContactView,
},
{
    path: "/settings",
    name: "Settings",
    component: SettingsView,
},
{
    path: "/todo",
    name: "ToDo",
    component: ToDoview,
    meta: {
        auth: true
    }
},
{
    path: "/survey",
    name: "Survey",
    component: SurveyView,
    meta: {
        auth: true
    }
},
{
    path: "/survey/result/:id",
    name: "SurveyResult",
    component: SurveyResultView,
    props: true,
    meta: {
        auth: true
    }
},
{
    path: "/survey/:id",
    name: "SurveyEdit",
    component: SurveyEditView,
    props: true,
    meta: {
        auth: true
    }
},
//catchall 404
{
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    console.log(store.state.userData);
    if (to.meta.auth && store.state.accessToken == null) {
        next('/account');
    } else {
        next();
        // return true;
    }
});

export default router;