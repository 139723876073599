<template>
  <div class="mainDiv">
    <h1>ToDo's</h1>
    <button type="button" class="btn btn-secondary">
      <i class="bi bi-plus-square" @click="this.newToDo = true"></i>
    </button>
    <div v-if="this.newToDo == true">
      <form @submit.prevent="handleSubmit" id="toDoForm">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Title</span>
          <input id="toDoTitle" type="text" class="form-control" />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="input-group">
          <span class="input-group-text">Content</span>
          <textarea id="toDoText" class="form-control"></textarea>
        </div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="this.newToDo = false"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-success">Add</button>
      </form>
    </div>
    <div
      v-for="todo in this.ToDos"
      :key="todo"
      class="card"
      v-bind:class="todo.status == 'done' ? 'done' : ''"
    >
      <div class="card-body">
        <h3 class="card-title">{{ todo?.title }}</h3>
        <p class="card-text">{{ todo?.text }}</p>
        <button
          @click="
            this.changeStatus(todo._id, todo.status == 'done' ? 'new' : 'done')
          "
          type="button"
          class="btn btn-secondary"
        >
          Change Status
        </button>
        <button
          @click="this.delete(todo._id)"
          type="button"
          class="btn btn-secondary"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ToDos: null,
      newToDo: false,
    };
  },
  methods: {
    handleSubmit() {
      const title = document.getElementById("toDoTitle").value;
      const text = document.getElementById("toDoText").value;
      axios
        .post(
          `${this.$url}/todos`,
          {
            title: title,
            text: text,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          }
        )
        .then((response) => {
          this.getToDos();
          this.newToDo = false;
        })
        .catch(() => {
          console.log("failed to create ToDo's");
        });
    },
    getToDos() {
      axios
        .get(`${this.$url}/api/todos`, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.ToDos = response.data;
        })
        .catch(() => {
          console.log("failed to fetch ToDo's");
        });
    },
    changeStatus(id, status) {
      axios
        .put(
          `${this.$url}/todos/${id}`,
          {
            status: status,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.getToDos();
        })
        .catch(() => {
          console.log("failed to change todo");
        });
    },
    delete(id) {
      axios
        .delete(`${this.$url}/api/todos/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.getToDos();
        })
        .catch(() => {
          console.log("failed to change todo");
        });
    },
  },
  mounted() {
    this.getToDos();
  },
};
</script>

<style scoped>
.mainDiv {
  background-color: white;
  margin: 30px;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
.done {
  background-color: grey;
}
</style>