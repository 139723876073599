<template>
  <div class="home">
    <LoginPromt
      v-if="logedIn == false"
      v-on:status1="changeStatus"
    ></LoginPromt>
    <div v-if="logedIn" class="mainDiv">
      <h1>Welcome Back {{ this.$store.state.userData?.username }}</h1>
      <button @click="getUserData()">GetUserData</button>
      <button @click="logout()">Logout</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoginPromt from "../components/LoginPromt.vue";
export default {
  components: {
    // SignUpForm,
    // LoginForm,
    LoginPromt,
  },
  data() {
    return {
      accessToken: "",
      logedIn: false,
      userdata: "",
    };
  },
  methods: {
    testIflogedIn: async function () {
      try {
        const token = localStorage.getItem("accessToken");
        if (token == null || token == "") return false;
        const response = await axios.get(`${this.$url}/api/user/test`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          this.accessToken = token;
          this.$store.commit("setAccessToken", token);
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    getUserData() {
      axios
        .get(`${this.$url}/api/user/own`, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.userdata = response.data;
          this.$store.commit("addUserData", response.data);
        })
        .catch(() => {
          console.log("failed to fetch userdata");
        });
    },
    changeStatus: function (loginStatus) {
      console.log(loginStatus);
      if (loginStatus == "success") {
        this.getUserData();
        this.logedIn = true;
      }
    },
    logout: function () {
      localStorage.removeItem("accessToken");
      this.$store.commit("addUserData", null);
      this.logedIn = false;
    },
  },
  mounted() {
    (async () => {
      if (this.$store.state.userData?.username != null) this.logedIn = true;
      this.$store.commit("setAccessToken", localStorage.getItem("accessToken"));
      const status = await this.testIflogedIn();
      if (status == true) {
        this.logedIn = true;
        // console.log("login");
        this.getUserData();
      } else {
        this.logedIn = false;
        // console.log("not login");
      }
    })();
  },
};
</script>

<style>
.mainDiv {
  background-color: white;
  margin: 30px;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
</style>