<template>
  <form @submit.prevent="handleSubmit" id="form">
    <h1>Contact</h1>
    <label>Your Name:</label>
    <input type="text" required v-model="name" />
    <label>Your Mail:</label>
    <input type="email" required v-model="mail" />
    <label>Your Message:</label>
    <textarea wrap="soft" required v-model="message"></textarea>
    <div class="submit">
      <p v-if="error" class="error">Unsuccessfull</p>
      <p v-if="success" class="success">Successfull</p>
      <button  type="button" @click="$router.go(-1)" class="btn btn-secondary">Cancel</button>
      <button type="submit" class="btn btn-success" btn>Send</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      mail: "",
      message: "",
      error: "",
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      if (this.name == "" || this.mail == "" || this.message == "") {
        this.error = "Please fill all fields";
      }
      console.log("send message");
      axios
        .post(`${this.$url}/contact`, {
          name: this.name,
          mail: this.mail,
          message: this.message,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 500) {
            this.error = "Server Error";
            return;
          }
          if (response.status == 204) {
            console.log("Success");
            this.success = true;
            this.$router.go(-1);
          }
        });
    },
  },
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
h1 {
  text-align: center;
}
label {
  color: hsl(0, 0%, 50%);
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
textarea {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
  resize: none;
}
.submit {
  text-align: center;
}
.error {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}
.success{
    color: hsl(145, 87%, 41%);
  margin-top: 10px;
  font-weight: bold;
}
</style>