<template>
  <div class="mainDiv2 container">
    <h1>Survey's</h1>
    <button
      class="btn btn-success btn-emphasize shadow"
      @click="createSurvey()"
    >
      New Survey
    </button>
    <article v-for="survey in surveys" :key="survey" class="card">
      <h2>{{ survey.name }}</h2>
      <p>{{ survey.description }}</p>
      <p>created at: {{ survey.createdAt }}</p>
      <p>Questions: {{ survey.questions.length }}</p>
      <a>Link: https://beniox.lerchen.net/survey?id={{ survey.number }}</a>
      <div>
        <router-link
          :to="{ name: 'SurveyEdit', params: { id: survey._id } }"
          class="btn btn-success btn-emphasize shadow margin"
        >
          Edit Survey
        </router-link>
        <router-link
          :to="{ name: 'SurveyResult', params: { id: survey._id } }"
          class="btn btn-success btn-emphasize shadow margin"
        >
          View Results
        </router-link>
        <button
          class="btn btn-outline-danger btn-emphasize shadow margin"
          @click="deleteSurvey(survey._id)"
        >
          Delete Survey
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      surveys: null,
    };
  },
  methods: {
    getSurveys: async function () {
      try {
        const surveys = await axios.get(`${this.$url}/api/survey`, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        });
        this.surveys = surveys.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createSurvey() {
      try {
        const response = await axios.post(
          `${this.$url}/api/survey`,
          {
            name: "New Survey",
            description: "New Survey Description",
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          }
        );
        console.log(response);
        this.$router.push({
          path: `/survey/${response.data}`,
          param: { id: response.data },
        });
      } catch (error) {
        console.log(error);
      }
    },
    async deleteSurvey(id) {
      try {
        //ask via alert if you want to delete
        if (confirm("Are you sure you want to delete this survey?")) {
          await axios.delete(`${this.$url}/api/survey/${id}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          });
        }
        this.getSurveys();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSurveys();
  },
};
</script>

<style scoped>
.mainDiv2 {
  background-color: white;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
  margin-top: 20px;
}

.margin {
  margin: 10px;
}
</style>