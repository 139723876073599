<template>
  <div class="container">
    <div class="div" v-if="option == ''">
      <h1>Please Login or Register</h1>
      <button @click="this.option = 'login'">Login</button>
      <button @click="this.option = 'register'">SignUp</button>
    </div>
    <signup-form
      v-if="option == 'register'"
      v-on:status="changeStatus"
      v-on:changeForm="changeForm"
    ></signup-form>
    <login-form
      v-if="option == 'login'"
      v-on:status="changeStatus"
      v-on:changeForm="changeForm"
    ></login-form>
  </div>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import SignupForm from "./SignupForm.vue";
export default {
  components: { SignupForm, LoginForm },
  data() {
    return {
      option: "login",
      loginStatus: "",
    };
  },
  methods: {
    changeStatus: function (loginStatus) {
      console.log(loginStatus);
      this.$emit("status1", "success");
    },
    changeForm: function (form) {
      this.option = form;
    },
  },
};
</script>

<style scoped>
.div {
  /* max-width: 420px; */
  /* margin: 30px auto; */
  background: white;
  text-align: left;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
h1 {
  color: black;
}
</style>