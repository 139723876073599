<template>
  <div>
<ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "../components/ContactForm.vue"
export default {
    components:{
    ContactForm
}
}
</script>

<style>

</style>