<template>
  <div class="mainDiv">
    <h1>Admin</h1>
    <div>
      <table>
        <thead>
          <th>Username</th>
          <th>ID</th>
          <th>Role</th>
          <th>Verified</th>
          <th>Create Date</th>
          <th>Delete User</th>
        </thead>
        <tbody>
          <tr v-for="user in userlist" :key="user">
            <td>{{ user.username }}</td>
            <td>{{ user._id }}</td>
            <td>
              <select
                :disabled="user._id == this.$store.state.userData?._id"
                class="form-select"
                aria-label="Default select example"
                :value="user.role"
                @change="changeRole(user._id, $event?.target.value)"
              >
                <option value="basic">basic</option>
                <option value="admin">admin</option>
              </select>
            </td>
            <td>
              <div>
                <p>{{ user.verified }}</p>
                <input
                  :disabled="user._id == this.$store.state.userData?._id"
                  type="checkbox"
                  v-model="user.verified"
                  @click="verifyUser(user._id, this.checked)"
                />
              </div>
            </td>
            <td>{{ user.createdAt }}</td>
            <td>
              <button
                :disabled="user._id == this.$store.state.userData?._id"
                type="button"
                class="btn btn-secondary"
                @click="deleteUser(user._id)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userlist: null,
      accessToken: "",
    };
  },
  methods: {
    getAllUsers: function () {
      axios
        .get(`${this.$url}/api/user`, {
          headers: { Authorization: `Bearer ${this.accessToken}` },
        })
        .then((response) => {
          this.userlist = response.data;
        });
    },
    testIflogedIn: async function () {
      try {
        const token = localStorage.getItem("accessToken");
        if (token == null || token == "") return false;
        const response = await axios.get(`${this.$url}/api/user/test`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          this.accessToken = token;
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    verifyUser: function (id, bool) {
      console.log(bool);
      axios
        .put(
          `${this.$url}/api/user/${id}`,
          { verify: true },
          {
            headers: { Authorization: `Bearer ${this.accessToken}` },
          }
        )
        .then((response) => {
          if (response == 202) {
            console.log("verified successful");
            this.getAllUsers();
          }
        });
    },
    deleteUser: function (id) {
      axios
        .delete(`${this.$url}/api/user/${id}`, {
          headers: { Authorization: `Bearer ${this.accessToken}` },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("Deletion successful");
            this.getAllUsers();
          }
        });
    },
    changeRole(id, role) {
      if (id == this.$store.state.userData?._id) {
        return;
      }
      axios
        .put(
          `${this.$url}/api/user/${id}`,
          { role: role },
          {
            headers: { Authorization: `Bearer ${this.accessToken}` },
          }
        )
        .then((response) => {
          this.getAllUsers();
        });
    },
  },
  mounted() {
    (async () => {
      const status = await this.testIflogedIn();
      console.log(status);
      if (status == true) {
        console.log("login");
        this.getAllUsers();
      } else {
        console.log("not login");
      }
    })();
  },
};
// console.log(this.applist)
</script>

<style scoped>
.mainDiv {
  background-color: white;
  margin: 30px;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
table {
  border-collapse: collapse;
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-size: 0.8rem;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px 20px;
}

td {
  text-align: center;
}

caption {
  padding: 10px;
}
i {
  font-size: 18px;
}
i:hover {
  color: gray;
}
p {
  display: inline;
}
button {
  margin: 0;
}
</style>