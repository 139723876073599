<template>
  <form @submit.prevent="handleSubmit" class="container">
    <h1>Login</h1>
    <label>Username:</label>
    <input type="text" required v-model="username" />
    <label>Password:</label>
    <input type="password" required v-model="password" />
    <div class="submit">
      <button
        type="button"
        class="btn btn-secondary"
        @click="this.$emit('changeForm', 'register')"
      >
        Register instead
      </button>
      <p v-if="loginError" class="error">Unsuccessfull</p>
      <button type="submit" class="btn btn-success">Login</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      role: "designer",
      terms: false,
      loginError: false,
    };
  },
  methods: {
    handleSubmit() {
      axios
        .post(`${this.$url}/api/user/login`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            // console.log("login successfull");
            localStorage.setItem("accessToken", response.data.accessToken);
            this.$store.commit(
              "setAccessToken",
              localStorage.getItem("accessToken")
            );
            this.loginError = false;
            this.$emit("status", "loggedin");
          } else {
            console.log("Failed to login");
            this.loginError = true;
          }
        })
        .catch(() => {
          this.loginError = true;
        });
    },
  },
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
label {
  color: hsl(0, 0%, 50%);
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}
.submit {
  text-align: center;
}
.error {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.success {
  color: rgb(1, 133, 45);
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: bold;
}
h1 {
  text-align: center;
}
</style>