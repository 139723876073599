import { createStore } from 'vuex'
const store = createStore({
    state: {
        userData: null,
        accessToken: null,
    },
    mutations: {
        addUserData(state, value) {
            state.userData = value;
        },
        setAccessToken(state, value) {
            state.accessToken = value;
        }
    }
});

export default store;