<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light nav">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link :to="{ name: 'Home' }" class="nav-link navl"
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Account' }" class="nav-link navl"
              >Account</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'ToDo' }"
              v-if="this.$store.state.userData != null"
              class="nav-link navl"
              >ToDo</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'Survey' }"
              v-if="this.$store.state.userData != null"
              class="nav-link navl"
              >Survey</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'Admin' }"
              v-if="this.$store.state.userData?.role == 'admin'"
              class="nav-link navl"
              >AdminView</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'About' }" class="nav-link navl"
              >About</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.nav {
  background-color: var(--charcoal) !important;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid black;
}

.navl {
  font-weight: bold;
  color: var(--dark-sea-green) !important;
}

.nav .navl.router-link-exact-active {
  color: #42b983 !important;
  background: rgba(200, 200, 200, 0.1) !important;
  border-radius: 15px;
}
</style>