<template>
  <div class="container">
    <ul>
      <li
        v-for="tag in tags"
        :key="tag"
        class="tags"
        @click="toggleTags(tag)"
        :class="{ act: tagsToShow.indexOf(tag) !== -1 }"
      >
        {{ tag }}
      </li>
    </ul>
    <ul>
      <li
        class="card2"
        v-for="item in filterdApps"
        :key="item.name"
        @click="openSite(item.link)"
      >
        <h2>{{ item.name }}</h2>
        <img :src="require(`../assets/${item.img}`)" />
        <p>{{ item.description }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import applist from "../assets/AppList.json";
export default {
  data() {
    return {
      appList: applist,
      tags: [],
      tagsToShow: [],
    };
  },
  methods: {
    openSite(url) {
      if (url.includes("://")) {
        window.open(url);
      } else {
        window.open("https://beniox.lerchen.net/" + url);
      }
    },
    toggleTags(tag) {
      this.tagsToShow.indexOf(tag) === -1
        ? this.tagsToShow.push(tag)
        : (this.tagsToShow = this.tagsToShow.filter((item) => item !== tag));
    },
    getTags() {
      applist.forEach((tags) => {
        tags = tags.tags;
        tags.forEach((tag) => {
          if (this.tags.indexOf(tag) === -1) this.tags.push(tag);
        });
      });
    },
  },
  beforeMount() {
    this.getTags();
  },
  computed: {
    filterdApps() {
      //   return this.appList.filter(
      //     (item) =>
      //       (item.tags.every((i) => this.tagsToShow.includes(i)) &&
      //         item.tags != "") ||
      //       this.tagsToShow == ""
      //   );

      return this.appList.filter(
        (item) =>
          (item.tags.every((val) => this.tagsToShow.includes(val)) &&
            item.tags != "") ||
          this.tagsToShow == ""
      );
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.card2 {
  list-style: none;
  border: 2px solid transparent;
  border-radius: 10px;
  margin: 10px;
  padding: 1px;
  width: 400px;
  background-color: #2f3e46ff;
  box-shadow: 1px 1px black;
  width: 300px;
  color: white;
}
li:hover {
  cursor: pointer;
}
h2,
p {
  color: #cad2c5ff;
  margin: 10px;
  font-family: Garamond, serif;
}
img {
  width: 100%;
  height: 70%;
  border-radius: 2px;
}
.tags {
  list-style: none;
  border: 2px solid black;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 1px 1px black;
  width: 100px;
  background-color: #cad2c5ff;
  color: #2f3e46ff;
  padding: 2px;
  opacity: 0.2;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tags:hover {
  cursor: pointer;
}
.tags.act {
  background-color: #84a98cff;
  opacity: 1;
}
</style>