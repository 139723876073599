<template>
  <div class="home">
    <h1>My Projects</h1>
    <AppView />
  </div>
</template>

<script>
import AppView from "../components/AppView.vue";
export default {
  name: "Home",
  components: {
    AppView,
  },
};
</script>

<style scoped>
.home {
  /* background-color: #354f52ff; */
  padding: 10px;
  background: rgb(31, 30, 36);
  background: linear-gradient(
    119deg,
    rgba(31, 30, 36, 1) 0%,
    rgba(43, 72, 93, 1) 21%,
    rgba(56, 81, 101, 1) 50%,
    rgba(64, 77, 89, 1) 67%,
    rgba(50, 56, 66, 1) 87%,
    rgba(48, 47, 54, 1) 100%
  );
}
h1 {
  color: white;
  /* margin-top: 0; */
}
</style>