<template>
  <div class="mainDiv container">
    <article v-if="survey != null" class="container">
      <h1>Survey Edit</h1>

      <div class="mb-3">
        <label for="name" class="form-label">Title: </label>
        <input type="text" v-model="survey.name" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Description: </label>
        <input type="text" v-model="survey.description" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="validTill" class="form-label">valid till: </label>
        <input type="date" v-model="survey.validTill" class="form-control" />
      </div>
      <h2>Questions:</h2>
      <div
        v-for="question in survey.questions"
        :key="question"
        class="question"
      >
        <div class="mb-3" v-if="question.image != null">
          <img :src="question.image" />
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Upload Image</label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            accept="image/png, image/jpeg"
            placeholder="Upload Image"
            @change="saveImage($event, question)"
          />
        </div>

        <div class="input-group mb-3">
          <label for="question" class="input-group-text">Question: </label>
          <input type="text" class="form-control" v-model="question.text" />
        </div>
        <div class="input-group mb-3">
          <label for="select" class="input-group-text">Type: </label>
          <select v-model="question.type" class="form-select">
            <option value="text">text</option>
            <option value="number">number</option>
            <option value="date">date</option>
            <option value="time">time</option>
            <option value="select">select</option>
          </select>
        </div>

        <button
          @click="removeQuestion(question)"
          class="btn btn-outline-danger"
        >
          remove
        </button>
      </div>
      <button type="button" class="btn btn-primary" @click="addQuestion">
        Add Question
      </button>
      <article>
        <h2>CoAuthors:</h2>
        <div v-for="coAuthor in survey.coAuthors" :key="coAuthor">
          <p>{{ coAuthor.username }}</p>
          <button
            @click="removeCoAuthor(coAuthor)"
            class="btn btn-outline-danger"
          >
            Remove
          </button>
        </div>
        <div>
          <input type="text" v-model="newCoAuthor" />
          <button type="button" class="btn btn-primary" @click="addCoAuthor">
            Add CoAuthor
          </button>
        </div>
      </article>

      <button type="button" class="btn btn-success" @click="saveSurvey">
        Save
      </button>
      <div id="alertD" class="alert alert-danger" role="alert">
        There was a problem saving the survey.
      </div>
    </article>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      survey: null,
      newCoAuthor: "",
    };
  },
  methods: {
    getSurvey: async function () {
      try {
        const survey = await axios.get(`${this.$url}/api/survey/${this.id}`, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        });
        this.survey = survey.data;
        console.log("got survey");
      } catch (error) {
        console.log(error);
      }
    },
    addQuestion: function () {
      this.survey.questions.push({
        text: "",
        type: "text",
        answer: "",
        options: [],
        required: false,
        image: "",
      });
    },
    removeQuestion: function (question) {
      const index = this.survey.questions.indexOf(question);
      this.survey.questions.splice(index, 1);
    },
    saveSurvey: async function () {
      try {
        await axios.put(`${this.$url}/api/survey/${this.id}`, this.survey, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        });
        console.log("saved survey");
      } catch (error) {
        console.log(error);
        document.getElementById("alertD").style.display = "block";
      }
    },
    addCoAuthor: function () {
      this.survey.coAuthors.push({ username: this.newCoAuthor });
      this.newCoAuthor = "";
    },
    removeCoAuthor: function (coAuthor) {
      const index = this.survey.coAuthors.indexOf(coAuthor);
      this.survey.coAuthors.splice(index, 1);
    },
    saveImage: async function (event, question) {
      const file = event.target.files[0];
      //convert file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        const base64 = reader.result;
        //save base64 to server
        console.log(base64);
        question.image = base64;
        return base64;
      };
    },
  },
  mounted() {
    this.getSurvey();
  },
};
</script>

<style scoped>
.mainDiv {
  background-color: white;
  margin: 30px;
  padding: 40px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}
.question {
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
}
#alertD {
  display: none;
}
</style>